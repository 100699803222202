export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_RESET = "USER_CREATE_RESET";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";

export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";

export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_RESET = "USER_LIST_RESET";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";

export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
