export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_RESET = "FORGET_PASSWORD_RESET";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";
export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_RESET = "PROFILE_UPDATE_RESET";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";

export const SIGNIN_FAIL = "SIGNIN_FAIL";
export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_RESET = "SIGNIN_RESET";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";

export const SIGNOUT = "SIGNOUT";

export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
