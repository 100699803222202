export const GROUP_CREATE_FAIL = "GROUP_CREATE_FAIL";
export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_RESET = "GROUP_CREATE_RESET";
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";

export const GROUP_DELETE_FAIL = "GROUP_DELETE_FAIL";
export const GROUP_DELETE_REQUEST = "GROUP_DELETE_REQUEST";
export const GROUP_DELETE_RESET = "GROUP_DELETE_RESET";
export const GROUP_DELETE_SUCCESS = "GROUP_DELETE_SUCCESS";

export const GROUP_GAME_DETAILS_FAIL = "GROUP_GAME_DETAILS_FAIL";
export const GROUP_GAME_DETAILS_REQUEST = "GROUP_GAME_DETAILS_REQUEST";
export const GROUP_GAME_DETAILS_RESET = "GROUP_GAME_DETAILS_RESET";
export const GROUP_GAME_DETAILS_SUCCESS = "GROUP_GAME_DETAILS_SUCCESS";

export const GROUP_GAME_LIST_FAIL = "GROUP_GAME_LIST_FAIL";
export const GROUP_GAME_LIST_REQUEST = "GROUP_GAME_LIST_REQUEST";
export const GROUP_GAME_LIST_RESET = "GROUP_GAME_LIST_RESET";
export const GROUP_GAME_LIST_SUCCESS = "GROUP_GAME_LIST_SUCCESS";

export const GROUP_GAME_UPDATE_FAIL = "GROUP_GAME_UPDATE_FAIL";
export const GROUP_GAME_UPDATE_REQUEST = "GROUP_GAME_UPDATE_REQUEST";
export const GROUP_GAME_UPDATE_RESET = "GROUP_GAME_UPDATE_RESET";
export const GROUP_GAME_UPDATE_SUCCESS = "GROUP_GAME_UPDATE_SUCCESS";

export const GROUP_LIST_FAIL = "GROUP_LIST_FAIL";
export const GROUP_LIST_REQUEST = "GROUP_LIST_REQUEST";
export const GROUP_LIST_RESET = "GROUP_LIST_RESET";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
