export const ARTICLE_CREATE_FAIL = "ARTICLE_CREATE_FAIL";
export const ARTICLE_CREATE_REQUEST = "ARTICLE_CREATE_REQUEST";
export const ARTICLE_CREATE_RESET = "ARTICLE_CREATE_RESET";
export const ARTICLE_CREATE_SUCCESS = "ARTICLE_CREATE_SUCCESS";

export const ARTICLE_DELETE_FAIL = "ARTICLE_DELETE_FAIL";
export const ARTICLE_DELETE_REQUEST = "ARTICLE_DELETE_REQUEST";
export const ARTICLE_DELETE_RESET = "ARTICLE_DELETE_RESET";
export const ARTICLE_DELETE_SUCCESS = "ARTICLE_DELETE_SUCCESS";

export const ARTICLE_DETAILS_FAIL = "ARTICLE_DETAILS_FAIL";
export const ARTICLE_DETAILS_REQUEST = "ARTICLE_DETAILS_REQUEST";
export const ARTICLE_DETAILS_RESET = "ARTICLE_DETAILS_RESET";
export const ARTICLE_DETAILS_SUCCESS = "ARTICLE_DETAILS_SUCCESS";

export const ARTICLE_LIST_FAIL = "ARTICLE_LIST_FAIL";
export const ARTICLE_LIST_REQUEST = "ARTICLE_LIST_REQUEST";
export const ARTICLE_LIST_RESET = "ARTICLE_LIST_RESET";
export const ARTICLE_LIST_SUCCESS = "ARTICLE_LIST_SUCCESS";

export const ARTICLE_UPDATE_FAIL = "ARTICLE_UPDATE_FAIL";
export const ARTICLE_UPDATE_REQUEST = "ARTICLE_UPDATE_REQUEST";
export const ARTICLE_UPDATE_RESET = "ARTICLE_UPDATE_RESET";
export const ARTICLE_UPDATE_SUCCESS = "ARTICLE_UPDATE_SUCCESS";
